.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button {
  font-family: "plus-jakarta-sans", sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slick-arrow {
  width: 32px;
  height: 32px;
}

.slick-next::before{
  display: block;
  position: relative;

  content: "";
  width:100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("./assets/images/right-arrow.svg");
}
.slick-prev::before{
  display: block;
  position: relative;

  content: "";
  width:100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("./assets/images/left-arrow.svg");
}

.slick-dots {

  li {
    button::before {
      color: #ccdff9;
      font-size: 8px;
      opacity: 1;
    }
  }

  li.slick-active {
    button::before {
      color: #3070CC;
      font-size: 8px;
      opacity: 1;
    }
  }
}

.slider-container  div[data-index] {
  display: flex;
  justify-content: center;
  cursor: pointer;
}



