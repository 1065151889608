@font-face {
  font-family: "plus-jakarta-sans";
  src: url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "plus-jakarta-sans";
  src: url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "plus-jakarta-sans";
  src: url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "plus-jakarta-sans";
  src: url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "plus-jakarta-sans";
  src: url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "plus-jakarta-sans";
  src: url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraBoldItalic.ttf");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "plus-jakarta-sans";
  src: url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "plus-jakarta-sans";
  src: url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "plus-jakarta-sans";
  src: url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "plus-jakarta-sans";
  src: url("./assets/fonts/PlusJakartaSans/PlusJakartaSans-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
